.full-screen-generic {
  display: flex;
  min-height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: flex-start;
  padding: 50px 0 100px 0;
  overflow-y: auto;
  background: linear-gradient(
    to bottom,
    var(--bg-color) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

@media (max-width: 768px) {
}
