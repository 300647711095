.full-screen-landing {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    to bottom,
    var(--bg-color) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.textContainer {
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  text-align: center;
  z-index: 1;
  color: #fff;
  text-shadow: 1px 1px 3px #000;
}

.landing .icon {
  text-decoration: none;
  font-size: 22px;
  padding: 10px;
  transition: 0.6s ease-in;
}

.text-button i {
  margin-left: 8px; /* Space between text and icon */
  transform: rotate(-45deg); /* Rotates the icon to point upper-right */
}

.text-button:hover {
  color: #007bff; /* Change text color on hover */
}

.about-me-btn {
  font-family: "Playfair Display", serif; /* Apply Playfair Display font */
  font-size: 15; /* Set font size */
  font-weight: 600; /* Slightly bold */
  background-color: transparent; /* Transparent background */
  text-align: center; /* Center text */
  display: inline-flex; /* Align text and icon */
  align-items: center; /* Center the content vertically */
  transition: all 0.3s ease; /* Smooth hover transition */
}

.bottom-container {
  margin-top: 50px;
}

.bottom-button-container {
  display: flex; /* Use flexbox to align the children */
  flex-direction: column;
  align-items: flex-end; /* Center the items vertically */
}

/* --- MEDIA QUERIES --- */

@media (min-width: 768px) {
  .landing {
    display: flex;
    height: calc(100% - 70px);
    width: calc(50% - 30px);
    justify-content: center;
    align-items: center;
  }

  .full-screen-landing {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      to bottom,
      var(--bg-color) 0%,
      rgba(0, 0, 0, 0.8) 100%
    );
  }

  .bottom-container {
    margin-top: 50px;
    width: 45vw;
    display: flex; /* Use flexbox to align the children */
    justify-content: space-between; /* Center the items horizontally */
    flex-wrap: wrap; /* Allow items to wrap to the next line if there's not enough space */
  }

  .bottom-button-container {
    display: flex; /* Use flexbox to align the children */
    flex-direction: column;
    gap: 10px;
  }

  .text-button {
    background: none; /* No background */
    color: white; /* White text */
    font-size: 16px; /* Text size */
    border: none; /* No border */
    padding: 0; /* No padding */
    cursor: pointer; /* Pointer cursor on hover */
    display: flex; /* Flex container to align text and icon */
    align-items: center; /* Vertically center align text and icon */
  }

  .description {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .landing {
    display: flex;
    height: calc(100%);
    width: calc(50%);
    justify-content: center;
    align-items: center;
  }

  .full-screen-landing {
    display: flex;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

  .text-button {
    background: none; /* No background */
    color: white; /* White text */
    font-size: 16px; /* Text size */
    border: none; /* No border */
    padding: 0; /* No padding */
    cursor: pointer; /* Pointer cursor on hover */
    display: flex; /* Flex container to align text and icon */
    align-items: center; /* Vertically center align text and icon */
    margin-bottom: 25%;
  }

  .description {
    font-size: 15px;
  }
}

.role-title {
  font-size: 1.5em;
  font-weight: 500;
}
