/* --- MOBILE STYLES --- */

.companyTitle {
  font-size: 12px;
  padding: 10px 0;
}

.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing);
  min-height: 100%;
  width: calc(70%);
}

.projectCard {
  width: 100%; /* Makes it responsive */
  color: white;
  border-radius: 15px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
  flex-direction: column;
  padding: 15px !important;
  cursor: pointer;
}

.textWrap {
  transition: all 0.9s ease-in-out;
  z-index: 0;
}

.projectLink {
  text-decoration: none;
}

.imageContainer img {
  height: 12em;
  margin-top: 25px;
  transition: all 1.3s ease-in-out;
}

.tech {
  font-size: 12px;
  white-space: nowrap;
}

.viewWork {
  font-size: 14px;
}

.projectTitle {
  font-size: 15px;
  padding: 7px 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.githubLink {
  text-decoration: none;
}

.projectCard::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
}

.closeModal {
  margin-left: 250px;
  margin-top: -20px;
}

.closeModal:hover {
  filter: brightness(100);
}

.modalTitle {
  color: white;
  white-space: nowrap;
}

.projectDescription {
  margin-bottom: 10px;
}

.projectModalTitle {
  margin-bottom: 10px;
  font-size: 15px;
  font-family: "Source Sans Pro", Arial, sans-serif;
  font-weight: 700;
  line-height: 2;
  color: #777777;
}

.portfolio-description {
  color: #a1c6ea; /* A whiteish blue */
  margin-top: 10px;
  line-height: 1.8;
  font-size: 18px; /* Smaller font for a more relaxed vibe */
  font-weight: normal; /* Lighter font weight for readability */
  margin-bottom: 20px;
  width: 95%;
  white-space: pre-line; /* This will preserve line breaks for natural spacing */
}

/* Group Section Styling */
.portfolio-groups {
  margin-top: 20px;
}

.portfolio-group {
  margin-bottom: 30px;
  padding: 20px;
  background: #181818; /* Even darker for contrast */
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.group-title {
  font-size: 22px;
  font-weight: bold;
  color: #ffffff; /* White text for contrast */
  margin-bottom: 15px;
  padding-left: 10px;
  border-left: 5px solid #007bff;
}

/* Show All Button */
.show-all-btn {
  display: block;
  margin: 15px auto 0;
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #003366; /* Darker blue text for contrast */
  background-color: #e0f2ff; /* Lighter whitish-blue */
  border: none;
  border-radius: 50px; /* Fully rounded button */
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.show-all-btn:hover {
  background-color: #b3e0ff; /* Slightly darker shade */
}

.read-more {
  font-size: 0.9rem; /* Slightly smaller font */
  color: #0073e6; /* A more modern blue (not too bright, not too dull) */
  font-weight: 500; /* Medium weight for better readability */
  cursor: pointer;
  transition: color 0.3s ease-in-out, text-decoration 0.3s ease-in-out;
}

.read-more:hover {
  color: #005bb5; /* Darker blue on hover for a subtle effect */
  text-decoration: underline;
}

/* --- MEDIA QUEIRES --- */

@media (min-width: 750px) {
  .companyTitle {
    font-size: 12px;
    padding: 10px 0;
  }

  .projectCard {
    flex-direction: row;
    padding: var(--sm-spacing) !important;
    height: 150px;
  }

  .portfolio {
    padding-left: var(--spacing);
    padding-right: var(--spacing);
  }

  .imageContainer {
    transform: translateX(30%);
    transition: all 0.3s ease-in-out;
  }

  .projectCard:hover::before {
    opacity: 0;
  }

  .imageContainer {
    transform: translateX(30%);
    transition: all 0.3s ease-in-out;
  }

  .textWrap {
    text-shadow: none;
  }

  .projectTitle {
    font-size: 15px;
    padding: 7px 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }

  .viewWork {
    font-size: 16px;
  }

  .tech {
    font-size: 14px;
  }
}

.email-button-container {
  display: flex; /* Set container to flex */
  justify-content: flex-start; /* Aligns items to the start of the container */
  align-items: flex-start; /* Aligns button to the start vertically */
  margin-bottom: 20px; /* Adds space between the button and the description */
}
