.now-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--spacing);
  min-height: 100%;
  width: calc(70%);
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.5s ease-out;
}

.now-content.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.now-content.fade-out {
  opacity: 0;
  transform: translateY(20px);
}

.header-container {
  display: flex;
  width: 100%;
  margin-bottom: 5%;
  position: relative;
  padding-bottom: 15px;
}

.header-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    transparent,
    var(--hl-color),
    transparent
  );
}

.contentDescription {
  color: var(--secondary-text-color);
  line-height: 1.6;
  text-align: justify;
  max-width: 800px;
  margin: 0 auto;
}

.infoContainer {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 25px;
  margin: 20px 0;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.infoContainer a {
  color: var(--hl-color);
  transition: all 0.3s ease;
  position: relative;
  text-decoration: none;
}

.infoContainer a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: var(--hl-color);
  transition: width 0.3s ease;
}

.infoContainer a:hover::after {
  width: 100%;
}

@media (min-width: 768px) {
  .now-content {
    width: calc(50% - 70px);
  }

  .infoContainer {
    transform: translateY(0);
    opacity: 1;
    transition: all 0.5s ease;
  }

  .infoContainer:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  }
}
